import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { useParams, Link } from 'react-router-dom';
import { Avatar, Box, Button, Card, CardContent, CardHeader, Chip, Divider, Stack, Typography } from '@mui/material';
import { useStyles } from '../../styles/styles';
import PlaceIcon from '@mui/icons-material/Place';
import WorkIcon from '@mui/icons-material/Work';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ExpertiseIcon from '../../assets/images/cards/icon_expertise.svg';
import WorkArrangementIcon from '../../assets/images/cards/icon_work.svg';
import LocationIcon from '../../assets/images/cards/icon_location.svg';
import SalaryIcon from '../../assets/images/cards/icon_salary.svg';
import { format } from '../../utils/DateUtil';
import { ITool } from '../../interfaces/ITool';
import { ISkill } from '../../interfaces/ISkill';
import { formateBudgetRange } from '../../helpers/Helpers';
// import { PromoCard } from '../common/PromoCard';
import { ISection } from '../../interfaces/ISection';
import { Constants } from '../../utils/Constants';
import { ApplyForm } from './ApplyForm';
import { IPublicPage } from '../../interfaces/IPublicPage';
import { getDataById } from '../../services/PublicPagesApi';
import Navbar from '../common/Navbar';
import SEO from '../common/SEO';
import { setIndividualUser } from '../../actions/UsersActions';
import { getTalentCloudOwner } from '../../services/UserApi';
import { IUser } from '../../interfaces/IUser';

export const Overview = () => {
  const { id, identifier } = useParams();
  const dispatch = useDispatch();
  const data: IPublicPage = useSelector((state: RootState) => state.publicPages.byIdData);
  const section: ISection = useSelector((state: RootState) => state.sections.sectionData);
  const classes = useStyles();
  const [openApplyForm, setOpenApplyForm] = useState(false);
  const owner = useSelector((state: RootState) => state.users.user);

  useEffect(() => {
    if (identifier === 'talent_cloud' && data.owner_id) dispatch(getTalentCloudOwner(data.owner_id));
  }, [dispatch, data, identifier]);

  const handleClickOpen = () => {
    setOpenApplyForm(true);
  };

  useEffect(() => {
    dispatch(getDataById(id, identifier));
  }, [identifier, id, dispatch]);

  useEffect(() => {
    dispatch(setIndividualUser({} as IUser));
    window.scrollTo(0, 0);
  }, [dispatch]);

  return (
    <>
      <SEO title='Job Opportunity | Project | Overview' />
      <Navbar />
      {data && (
        <Box sx={{ marginTop: '3.5rem' }}>
          <Box className={classes.mainHeader}>
            <Box className={classes.contentContainer}>
              <Box className={classes.mainHeaderInner}>
                <Box className={classes.mainHeaderContent}>
                  <Link to={'/'} className={classes.mainHeaderReturn}>
                    <KeyboardArrowLeftIcon />
                    Back to Browse Jobs Opportunities
                  </Link>
                  <Typography variant='h3'>{data.job_title}</Typography>
                  <Stack className={classes.mainHeaderOrigin} direction='row'>
                    {identifier === 'talent_cloud' ? (
                      <>{owner?.first_name && <Typography className={`${classes.jobCardOrigin}`}>{`${owner.first_name} ${owner.last_name}`}</Typography>}</>
                    ) : (
                      <>{data.hiring_manager?.trim() !== '' && <Typography className={`${classes.jobCardOrigin}`}>{data.hiring_manager}</Typography>}</>
                    )}
                    <Typography>posted {format(data.created_at, 'MMM DD, YYYY')}</Typography>
                  </Stack>
                  <Stack className={classes.mainHeaderFeatures} direction='row'>
                    {data.ideal_candidate_location && (
                      <Typography>
                        <PlaceIcon />
                        {data.ideal_candidate_location}
                      </Typography>
                    )}
                    {data.job_type && (
                      <Typography>
                        <WorkIcon />
                        {data.job_type}
                      </Typography>
                    )}
                    {(data.min_rate || data.max_rate || data.remunerations) && (
                      <Typography>
                        <MonetizationOnIcon />
                        {data.remunerations} {(data.min_rate || data.max_rate) && formateBudgetRange(data.remunerations, data.min_rate, data.max_rate)}
                      </Typography>
                    )}
                    {data?.number_of_resources !== 0 && (
                      <Typography>
                        <PersonIcon />
                        {data.number_of_resources} {data.number_of_resources > 1 ? 'Positions' : 'Position'}
                      </Typography>
                    )}
                  </Stack>
                </Box>
                <Divider orientation='vertical' flexItem />
                <Box className={classes.mainHeaderActions}>
                  <Button size='large' variant='contained' color='success' sx={{ width: '100%' }} onClick={handleClickOpen}>
                    Apply
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.mainNav}>
            <Box className={classes.contentContainer}>
              <Box className={classes.mainNavInner}>
                <Typography className={classes.mainNavItem}>Overview</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.mainContent}>
            <Box className={classes.contentContainer}>
              <Box className={classes.jobDetails}>
                <Box className={classes.jobDetailsContent}>
                  <Card>
                    <CardContent>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.description ?? '',
                        }}
                      ></div>
                    </CardContent>
                  </Card>
                </Box>
                <Box className={classes.jobDetailsFeatures}>
                  <Card>
                    <CardHeader
                      avatar={
                        <Avatar sx={{ backgroundColor: '#4ba4da' }} aria-label='Expertise'>
                          <img src={ExpertiseIcon} alt='Expertise' />
                        </Avatar>
                      }
                      title='Expertise'
                    />
                    {section.expertise && (
                      <>
                        <CardContent>
                          {data.experience_level && data.experience_level.length > 0 && (
                            <Box className={classes.tagsGroup}>
                              <Typography variant='h5'>Experience Level</Typography>
                              <Box>
                                {data.experience_level.map((exp: string, index: number) => (
                                  <Chip label={exp} key={index} />
                                ))}
                              </Box>
                            </Box>
                          )}

                          {data.educations?.length > 0 && (
                            <Box className={classes.tagsGroup}>
                              <Typography variant='h5'>Preferred Education</Typography>
                              <Box>
                                {data.educations.map((edu: string, index: number) => (
                                  <Chip label={edu} key={index} />
                                ))}
                              </Box>
                            </Box>
                          )}
                          {data.certifications?.length > 0 && (
                            <Box className={classes.tagsGroup}>
                              <Typography variant='h5'>Preferred Certifications</Typography>
                              <Box>
                                {data.certifications.map((cert: string, index: number) => (
                                  <Chip label={cert} key={index} />
                                ))}
                              </Box>
                            </Box>
                          )}
                        </CardContent>
                        <Divider />
                      </>
                    )}
                    <CardContent>
                      {data.skills && data.skills.length > 0 && (
                        <Box className={classes.tagsGroup}>
                          <Typography variant='h5'>Functional Compentencies</Typography>
                          <Box>
                            {data.skills.map((skill: ISkill, index: number) => (
                              <Chip label={skill.name} key={index} />
                            ))}
                          </Box>
                        </Box>
                      )}
                      {data.tools && data.tools.length > 0 && (
                        <Box className={classes.tagsGroup}>
                          <Typography variant='h5'>Technology & Tools</Typography>
                          <Box>
                            {data.tools?.map((tool: ITool, index: number) => (
                              <Chip label={tool.name} key={index} />
                            ))}
                          </Box>
                        </Box>
                      )}
                      {data.industry && data.industry.length > 0 && (
                        <Box className={classes.tagsGroup}>
                          <Typography variant='h5'>Industry</Typography>
                          <Box>
                            {data.industry.map((industry: string, index: number) => (
                              <Chip label={industry} key={index} />
                            ))}
                          </Box>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                  {section.work_arrangement && (
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ backgroundColor: '#4ba4da' }} aria-label='Work Arrangement'>
                            <img src={WorkArrangementIcon} alt='Work Arrangement' />
                          </Avatar>
                        }
                        title='Work Arrangement'
                      />
                      <CardContent>
                        {data.job_type && (
                          <Stack className={classes.dataGroup}>
                            <Typography variant='h6'>Job Type</Typography>
                            <Typography>{data.job_type}</Typography>
                          </Stack>
                        )}
                        {data.time_commitment && (
                          <Stack className={classes.dataGroup}>
                            <Typography variant='h6'>Minimum Time Commitment</Typography>
                            <Typography>{data.time_commitment} Hours/Week</Typography>
                          </Stack>
                        )}
                        {data.preferred_working_hours && (
                          <Stack className={classes.dataGroup}>
                            <Typography variant='h6'>Preferred Working Hours</Typography>
                            <Typography>{data.preferred_working_hours}</Typography>
                          </Stack>
                        )}
                      </CardContent>
                    </Card>
                  )}
                  {section.location && (
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ backgroundColor: '#fff' }} aria-label='Location'>
                            <img src={LocationIcon} alt='Location' />
                          </Avatar>
                        }
                        title='Location'
                      />
                      <CardContent>
                        {data.ideal_candidate_location && (
                          <Stack className={classes.dataGroup}>
                            <Typography variant='h6'>Ideal Candidate Location</Typography>
                            <Typography>{data.ideal_candidate_location}</Typography>
                          </Stack>
                        )}
                        {data.open_to_remote && (
                          <Stack className={classes.dataGroup}>
                            <Typography variant='h6'>Open to Remote</Typography>
                            <Typography>{data.open_to_remote === true ? 'Yes' : 'No'}</Typography>
                          </Stack>
                        )}
                        {data.travel_requirement && (
                          <Stack className={classes.dataGroup}>
                            <Typography variant='h6'>Percentage of Travel Required</Typography>
                            <Typography>{data.travel_requirement}</Typography>
                          </Stack>
                        )}
                      </CardContent>
                    </Card>
                  )}
                  {section.compensation && (
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ backgroundColor: '#fff' }} aria-label='Compensation'>
                            <img src={SalaryIcon} alt='Compensation' />
                          </Avatar>
                        }
                        title='Compensation'
                      />
                      <CardContent>
                        {data.remunerations && (
                          <Stack className={classes.dataGroup}>
                            <Typography>{data.remunerations}</Typography>
                          </Stack>
                        )}
                        {(data.min_rate || data.max_rate) && (
                          <Stack className={classes.dataGroup}>
                            {Object.entries(Constants.emunerations.range_titles).map(([k, v], index: number) => {
                              return (
                                <Typography variant='h6' key={index}>
                                  {k === data.remunerations && v}
                                </Typography>
                              );
                            })}
                            <Typography>{formateBudgetRange(data.remunerations, data.min_rate, data.max_rate)}</Typography>
                          </Stack>
                        )}
                      </CardContent>
                    </Card>
                  )}
                </Box>
              </Box>
              {/* <PromoCard></PromoCard> */}
            </Box>
          </Box>
          <ApplyForm identifier={data.identifier} openApplyForm={openApplyForm} setOpenApplyForm={setOpenApplyForm}></ApplyForm>
        </Box>
      )}
    </>
  );
};
