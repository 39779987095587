import { IPublicPage } from './../interfaces/IPublicPage';
import { AnyAction } from 'redux';
import * as actionTypes from '../actions/PublicPageActions';
import { IPublicPageMeta } from '../interfaces/IPublicPageMeta';

export type State = {
  readonly publicPagesMetaData: IPublicPageMeta;
  readonly byIdData: IPublicPage;
  readonly apiErrors: any;
};

const initialState: State = {
  publicPagesMetaData: { records: [], meta: { page: { per_page: 0, page: 0, total: 0 } } },
  byIdData: {} as IPublicPage,
  apiErrors: [],
};

export const PublicPageReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_PUBLIC_PAGE_DATA: {
      return {
        ...state,
        publicPagesMetaData: {
          ...action.payload,
          records: action.payload.records ? action.payload.records.sort((a: IPublicPage, b: IPublicPage) => Date.parse(b.updated_at) - Date.parse(a.updated_at)) : [],
        },
      };
    }
    case actionTypes.GET_DATA_BY_ID: {
      return {
        ...state,
        byIdData: action.payload,
      };
    }
    case actionTypes.API_ERRORS: {
      return {
        ...state,
        apiErrors: action.errors,
      };
    }
    default:
      return state;
  }
};
