import { Constants } from '../utils/Constants';

export function formateBudgetRange(remunerations, min_rate, max_rate) {
  switch (remunerations) {
    case Constants.emunerations.titles.HourlyRate:
      return `$${max_rate}/hr`;
    default:
      return `$${min_rate} - $${max_rate}`;
  }
}

export function truncate(str = '') {
  return str.length > Constants.truncate.LIMIT ? str.substring(0, 3) + '...' : str;
}

export function formateFormErros(formState, formErrors, setFormErrors, reset) {
  if (formState.isSubmitted === true) {
    var updatedFormErrors = [...formErrors];
    if (Object.keys(formState.errors).length > 0) {
      Object.values(formState.errors).map((error) => {
        if (error && !updatedFormErrors.includes(error.message)) {
          updatedFormErrors = [...updatedFormErrors, error.message];
        }
        return updatedFormErrors;
      });
    }
    setFormErrors(updatedFormErrors);
    reset();
  }
}

export function clearErros(formErrors, setFormErrors, value, error) {
  if (formErrors.length > 0 && value === true) {
    var updatedFormErrors = [...formErrors];
    var index = updatedFormErrors.indexOf(error);
    if (index !== -1) {
      updatedFormErrors.splice(index, 1);
      setFormErrors(updatedFormErrors);
    }
  }
}
