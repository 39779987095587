import { Dispatch } from 'redux';
import { accountApi, talentApi } from './BaseApi';
import { setIndividualUser, setUsersData } from '../actions/UsersActions';

export const verifyEmail = (data: any) => async (dispatch: Dispatch) => {
  try {
    await accountApi.post('/account/exist', { email: data.email });
    data.recordType === 'opportunity'
      ? (window.location.href = `${process.env.REACT_APP_LANDING_FRONTEND_SERVICE}/signin?opportunityId=${data.recordId}&email=${data.email}`)
      : (window.location.href = `${process.env.REACT_APP_LANDING_FRONTEND_SERVICE}/signin?talentCloudId=${data.recordId}&email=${data.email}`);
  } catch (error: any) {
    data.recordType === 'opportunity'
      ? (window.location.href = `${process.env.REACT_APP_LANDING_FRONTEND_SERVICE}/signup/expert?opportunityId=${data.recordId}&email=${data.email}`)
      : (window.location.href = `${process.env.REACT_APP_LANDING_FRONTEND_SERVICE}/signup/expert?talentCloudId=${data.recordId}&email=${data.email}`);
  }
};

export const getTalentCloudOwner = (id: string) => async (dispatch: Dispatch) => {
  const response = await talentApi.get(`/talent_clouds/talent_cloud_owner/${id}`);
  dispatch(setIndividualUser(response.data));
};

export const getUsers = (ids: string[]) => async (dispatch: Dispatch) => {
  const response = await talentApi.get(`/talent_clouds/users/${ids}`);
  dispatch(setUsersData(response.data));
};
