import { combineReducers } from 'redux';
import { SectionReducer, State as SectionState } from './SectionReducer';
import { PracticeAreaReducer, State as PracticeAreaState } from './PracticeAreaReducer';
import { PublicPageReducer, State as PublicPageState } from './PublicPageReducer';
import { LoaderReducer, State as LoaderState } from './LoaderReducer';
import { UsersReducer, State as UsersState } from './UsersReducer';

export interface RootState {
  sections: SectionState;
  practiceAreas: PracticeAreaState;
  publicPages: PublicPageState;
  loader: LoaderState;
  users: UsersState;
}

export const rootReducer = combineReducers<RootState>({
  sections: SectionReducer,
  practiceAreas: PracticeAreaReducer,
  publicPages: PublicPageReducer,
  loader: LoaderReducer,
  users: UsersReducer,
});
