import { IPublicPageMeta } from './../interfaces/IPublicPageMeta';
import { IPublicPage } from './../interfaces/IPublicPage';
import { ISection } from '../interfaces/ISection';
import { Dispatch } from 'redux';
import { getSectionAction } from '../actions/SectionActions';
import { apiErrorAction, getDataByIdAction, getPublicPagesAction } from '../actions/PublicPageActions';
import { parseObject, parseSection } from '../utils/ParseObject';
import qs from 'qs';
import { oppApi, talentApi } from './BaseApi';
import { setLoadingAction } from '../actions/LoaderActions';
import { IFilter } from '../interfaces/IFIlter';

export const getPublicPagesData = (searchFilters: IFilter) => async (dispatch: Dispatch) => {
  dispatch(setLoadingAction(true));
  const { filters, keywords, page } = searchFilters;
  const url = filters ? `/talent_clouds/public_jobs?${filters}&page=${page}` : `/talent_clouds/public_jobs?page=${page}`;
  try {
    let response: IPublicPageMeta;
    if (keywords?.length > 0) {
      response = await talentApi.get(url, {
        params: {
          keywords,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      });
    } else {
      response = await talentApi.get(url);
    }

    dispatch(getPublicPagesAction({ records: response.data.records.map((a: IPublicPage) => parseObject(a)), meta: { page: response.data.meta } }));
  } catch (error: any) {
    dispatch(apiErrorAction(error));
  }
  dispatch(setLoadingAction(false));
};

export const getDataById = (id: string | undefined, identifier: string | undefined) => async (dispatch: Dispatch) => {
  try {
    const res = identifier === 'opportunity' ? await oppApi.get(`/opportunities/${id}`) : await talentApi.get(`/talent_clouds/${id}`);
    const page: IPublicPage = parseObject({ ...res.data, identifier: identifier });
    dispatch(getDataByIdAction(page));
    const sections: ISection = parseSection(page);
    dispatch(getSectionAction(sections));
  } catch (error: any) {
    dispatch(apiErrorAction(error));
  }
};
