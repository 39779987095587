import { makeStyles } from '@mui/styles';
import JobsBannerImage from '../assets/images/banners/jobs_bg.png';
import PromoCardBackgroundImage from '../assets/images/banners/card_promo_bg.jpg';
import FormAlertBackgroundIcon from '../assets/images/alert_danger.svg';

export const useStyles = makeStyles({
  selected: {
    paddingTop: '.5rem !important',
    paddingBottom: '.5rem !important',
    '&.Mui-selected': {
      backgroundColor: '#4ba4da !important',
      color: '#fff !important',
      '& .MuiAvatar-root': {
        color: '#4ba4da !important',
        backgroundColor: '#fff !important',
      },
      '& .MuiTypography-root': {
        color: '#fff !important',
      },
    },
  },

  // Main Filters
  mainFilters: {
    padding: '0 2rem',
    '@media (max-width: 768px)': {
      padding: '0 1.5rem',
    },
  },
  mainFiltersInner: {
    padding: '2rem 0',
    borderBottom: '1px solid #d1dbe3',
    '& > * + *': {
      marginTop: '1rem',
    },
    '@media (max-width: 768px)': {
      padding: '1.5rem 0',
    },
  },
  mainFiltersInline: {
    display: 'flex',
    '& .MuiOutlinedInput-root': {
      borderRadius: '0',
    },
    '& > .MuiFormControl-root': {
      '&:first-child': {
        '& .MuiOutlinedInput-root': {
          borderTopLeftRadius: '.25rem',
          borderBottomLeftRadius: '.25rem',
        },
      },
    },
    '& .MuiInputBase-root': {
      '&.for-range': {
        width: '7.5rem',
        flexShrink: 0,
      },
    },
    '& .MuiButtonBase-root': {
      width: '28rem',
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
    },
    '@media (max-width: 960px)': {
      flexDirection: 'column',
      '& .MuiOutlinedInput-root': {
        borderRadius: '.25rem !important',
      },
      '& > .MuiFormControl-root': {
        '&:first-child': {
          marginBottom: '.5rem',
        },
        '&:nth-child(2)': {
          marginBottom: '.5rem',
          width: '100% !important',
        },
      },
      '& .MuiButtonBase-root': {
        marginTop: '.5rem',
        width: '100% !important',
        borderTopLeftRadius: '0.25rem',
        borderBottomLeftRadius: '0.25rem',
      },
    },
    '@media (max-width: 768px)': {
      '& .MuiInputBase-root': {
        '&.for-range': {
          width: '100% !important',
        },
      },
    },
  },
  mainFiltersRadius: {
    width: '10rem',
  },
  mainFiltersSwitch: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  mainFiltersMore: {
    display: 'flex',
    alignItem: 'center',
  },
  // Main Header
  mainHeader: {
    // marginTop: '3.5rem',
    backgroundColor: '#fff',
    padding: '0 2rem',
    borderBottom: '1px solid #d1dbe3',
    minHeight: '6.5rem',
    '& > *': {
      minHeight: '6.5rem',
      height: '100%',
    },
    '@media (max-width: 768px)': {
      padding: '0 1.5rem',
    },
  },
  mainHeaderInner: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    minHeight: '6.5rem',
    padding: '1.5rem 0 1rem',
    '& > .MuiDivider-root': {
      margin: '0 1.5rem',
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: '1rem 0',
      '& > h3': {
        fontSize: '1.375rem !important',
      },
    },
  },
  mainHeaderContent: {
    flex: '1',
  },
  mainHeaderActions: {
    flex: '.2',
    minWidth: '15rem',
    '@media (max-width: 768px)': {
      width: '100%',
      marginTop: '1rem',
    },
  },
  mainHeaderReturn: {
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: '1.125rem',
    fontWeight: 500,
    color: '#90959f !important',
    marginLeft: '-.5rem',
    marginBottom: '2rem',
    '@media (max-width: 768px)': {
      fontSize: '1rem',
      marginBottom: '1rem',
    },
  },
  mainHeaderOrigin: {
    marginBottom: '.5rem',
    '& .MuiTypography-root': {
      fontSize: '1.125rem',
      '&.hiringManager': {
        fontWeight: 500,
        marginRight: '.5rem',
        '&::after': {
          content: '", "',
        },
      },
    },
    '& .MuiTypography-root + .MuiTypography-root': {
      '&::before': {
        content: '", "',
      },
    },
    '@media (max-width: 768px)': {
      '& .MuiTypography-root': {
        fontSize: '1rem',
      },
    },
  },
  mainHeaderFeatures: {
    '& .MuiTypography-root': {
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: '1rem',
      '& svg': {
        fontSize: '1rem',
        marginRight: '.25rem',
      },
    },
    '& > .MuiTypography-root + .MuiTypography-root': {
      marginLeft: '1rem',
    },
    '@media (max-width: 1280px)': {},
    '@media (max-width: 960px)': {
      flexDirection: 'column !important',
      '& > .MuiTypography-root + .MuiTypography-root': {
        marginLeft: '0',
        marginTop: '.125rem',
      },
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column !important',
      '& .MuiTypography-root': {
        fontSize: '.9375rem',
      },
      '& > .MuiTypography-root + .MuiTypography-root': {
        marginLeft: '0',
        marginTop: '.125rem',
      },
    },
  },
  // MainNav
  mainNav: {
    backgroundColor: '#fff',
    padding: '0 2rem',
    borderBottom: '1px solid #d1dbe3',
    '@media (max-width: 768px)': {
      padding: '0 1.5rem',
    },
  },
  mainNavInner: {
    padding: '.75rem 0 0',
  },
  mainNavItem: {
    display: 'inline-block',
    position: 'relative',
    fontSize: '1.125rem !important',
    fontWeight: '500 !important',
    textTransform: 'uppercase',
    padding: '0.75rem 0',
    letterSpacing: '-0.2px',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      height: '0.375rem',
      borderRadius: '0.25rem',
      backgroundColor: '#4a5162',
    },
    '@media (max-width: 768px)': {
      fontSize: '1rem !important',
    },
  },
  // Main Content
  mainContent: {
    padding: '2rem 2rem 4rem',
    '@media (max-width: 768px)': {
      padding: '2rem 1.5rem 2rem',
    },
  },
  contentContainer: {
    width: '100%',
    maxWidth: '87.5rem',
    margin: '0 auto',
    '& .MuiPagination-root': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '1.5rem 0',
      '& .Mui-selected': {
        backgroundColor: '#4ba4da',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#4ba4da',
          color: '#ffffff',
        },
      },
    },
    '& .MuiPaginationItem-root': {
      color: '#4a5162',
      borderRadius: '0px !important',
      margin: 'auto',
      '&:hover': {
        backgroundColor: '#4ba4da',
        color: '#ffffff',
      },
    },
  },
  // Jobs Banner
  jobsBanner: {
    marginTop: '3.5rem',
    padding: '2rem 2rem',
    backgroundImage: `url(${JobsBannerImage})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#4093d0',
    '@media (max-width: 1280px)': {
      backgroundPosition: 'right -12.5rem center',
    },
    '@media (max-width: 960px)': {
      padding: '2rem 1.5rem 7.5rem',
      backgroundSize: 'contain',
      backgroundPosition: 'center bottom',
    },
    '@media (max-width: 768px)': {
      padding: '3rem 1.5rem 6rem',
      backgroundSize: 'contain',
      backgroundPosition: 'center bottom',
    },
  },
  jobsBannerInner: {
    width: '100%',
    maxWidth: '87.5rem',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '24rem',
  },
  jobsBannerTitle: {
    fontSize: '3rem !important',
    fontWeight: '500 !important',
    lineHeight: '1.1 !important',
    color: '#f8f9fa !important',
    width: '100%',
    maxWidth: '65rem',
    margin: '0 0 1rem !important',
    '@media (max-width: 1280px)': {
      fontSize: '2.5rem !important',
      maxWidth: '45rem',
    },
    '@media (max-width: 960px)': {
      fontSize: '2.25rem !important',
      maxWidth: '100%',
    },
    '@media (max-width: 768px)': {
      fontSize: '2.125rem !important',
    },
  },
  jobsBannerText: {
    fontSize: '1.25rem !important',
    lineHeight: '1.2 !important',
    color: '#fdf7f7 !important',
    width: '100%',
    maxWidth: '60rem',
    '@media (max-width: 1280px)': {
      maxWidth: '45rem',
    },
    '@media (max-width: 960px)': {
      maxWidth: '100%',
    },
    '@media (max-width: 768px)': {
      fontSize: '1.125rem !important',
    },
  },
  // Job Card
  jobCard: {
    marginTop: '2rem',
    border: '1px solid #d1dbe3',
    borderRadius: '.25rem',
    backgroundColor: '#fff',
    boxShadow: '0 3px 6px rgba(0 0 0 / 16%) !important',
    '& .MuiCardContent-root': {
      display: 'flex',
      padding: '1.5rem',
      '& > .MuiDivider-root': {
        margin: '0 1.5rem',
      },
    },
    '@media (max-width: 768px)': {
      marginTop: '1.5rem',
      '& .MuiCardContent-root': {
        flexDirection: 'column',
        padding: '1.25rem',
        '& > .MuiDivider-root': {
          display: 'none',
        },
      },
    },
  },
  jobCardInfo: {
    flex: 1,
    '& .MuiTypography-root': {
      display: 'flex',
      '& a': {
        color: '#4ba4da !important',
        textDecoration: 'none !important',
      },
    },
    '@media (max-width: 768px)': {
      '& .MuiTypography-root': {
        fontSize: '1rem !important',
      },
    },
  },
  jobCardFeatures: {
    width: '19rem',
    '& .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1rem',
      '& svg': {
        fontSize: '1.125rem',
        marginRight: '.75rem',
      },
    },
    '& > .MuiTypography-root + .MuiTypography-root': {
      marginTop: '.25rem',
    },
    '@media (max-width: 768px)': {
      marginTop: '1rem',
      paddingTop: '1rem',
      width: '100%',
      borderTop: '1px solid #d1dbe3',
    },
  },
  jobCardTitle: {
    cursor: 'pointer',
    '& .MuiLink-root': {
      textDecoration: 'none',
    },
    '& .MuiTypography-root': {
      textDecoration: 'none !important',
    },
    '@media (max-width: 768px)': {
      '& .MuiTypography-root': {
        fontSize: '1.25rem !important',
      },
    },
  },
  jobCardOrigin: {
    marginBottom: '.5rem',
    '& .MuiTypography-root': {
      fontSize: '1.125rem',
      '&.hiringManager': {
        fontWeight: 500,
        marginRight: '.5rem',
        '&::after': {
          content: '", "',
        },
      },
    },
  },
  jobCardSumary: {
    '& > *': {
      margin: '.125rem 0 0',
      maxHeight: '4rem',
      overflow: 'hidden',
    },
  },
  // Promo Card
  promoCard: {
    marginTop: '2rem',
    backgroundImage: `url(${PromoCardBackgroundImage})`,
    backgroundPosition: 'top right',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    '& .MuiCardContent-root': {
      height: '20rem',
      padding: '3rem',
    },
    '@media (max-width: 768px)': {
      marginTop: '1.5rem',
      '& .MuiCardContent-root': {
        height: 'auto !important',
        padding: '2rem 1.5rem',
      },
    },
  },
  promoCardTitle: {
    fontSize: '2rem !important',
    fontWeight: '500 !important',
    color: '#2b3e5f !important',
    marginBottom: '.5rem !important',
    '@media (max-width: 768px)': {
      fontSize: '1.5rem !important',
      color: '#fff !important',
    },
  },
  promoCardText: {
    fontSize: '1.375rem !important',
    color: '#2b3e5f !important',
    lineHeight: '1.2 !important',
    width: '100%',
    maxWidth: '30rem',
    marginBottom: '1rem !important',
    '@media (max-width: 768px)': {
      fontSize: '1.125rem !important',
      color: '#fff !important',
    },
  },
  promoCardLink: {
    textDecoration: 'none !important',
  },
  // Job Details
  jobDetails: {
    display: 'flex',
    '& > * + *': {
      marginLeft: '2rem',
    },
    '@media (max-width: 960px)': {
      flexDirection: 'column',
      '& > * + *': {
        marginLeft: '0',
        marginTop: '1.5rem',
      },
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      '& > * + *': {
        marginLeft: '0',
        marginTop: '1.5rem',
      },
    },
  },
  jobDetailsContent: {
    flex: '1',
    '& .MuiCard-root': {
      marginTop: '2rem',
      boxShadow: '0 3px 6px rgba(0 0 0 / 16%) !important',
      '&:first-child': {
        marginTop: '0',
      },
      '& .MuiCardContent-root': {
        padding: '1.5rem',
        '& h1, & h2, & h3, & h4, & h5, & h6': {
          fontSize: '1.125rem !important',
          fontWeight: '500 !important',
          margin: '1rem 0 .25rem',
        },
        '& p': {
          fontSize: '1.0625rem !important',
          margin: '0 0 .75rem',
        },
        '& ul, & ol': {
          margin: '0 0 .75rem',
          paddingLeft: '1.25rem',
        },
        '& li': {
          fontSize: '1.0625rem !important',
          margin: '0 0 .125rem',
        },
      },
    },
    '@media (max-width: 768px)': {
      '& .MuiCard-root': {
        marginTop: '1.5rem',
        '&:first-child': {
          marginTop: '0',
        },
        '& .MuiCardContent-root': {
          padding: '1.25rem',
          '& p': {
            fontSize: '1rem !important',
          },
          '& li': {
            fontSize: '1rem !important',
          },
        },
      },
    },
  },
  jobDetailsFeatures: {
    width: '32.5rem',
    '& .MuiCard-root': {
      marginTop: '2rem',
      boxShadow: '0 3px 6px rgba(0 0 0 / 16%) !important',
      '&:first-child': {
        marginTop: '0',
      },
      '& .MuiCardHeader-root': {
        padding: '1.5rem 1.5rem .75rem',
        '& .MuiCardHeader-avatar': {
          marginRight: '.75rem !important',
        },
        '& .MuiAvatar-root': {
          width: '2rem !important',
          height: '2rem !important',
        },
        '& .MuiTypography-root': {
          fontSize: '1.375rem !important',
          fontWeight: '500 !important',
        },
      },
      '& .MuiCardContent-root': {
        padding: '.75rem 1.5rem 1.5rem',
        '& > *': {
          margin: '0 0 .75rem',
          '&:last-child': {
            marginBottom: '0',
          },
        },
      },
      '& > .MuiDivider-root': {
        margin: '0 0 .75rem',
      },
      '&.no-header': {
        '& .MuiCardContent-root': {
          padding: '1.5rem',
        },
      },
    },
    '@media (max-width: 1280px)': {
      width: '27.5rem',
    },
    '@media (max-width: 960px)': {
      width: '100% !important',
    },
    '@media (max-width: 768px)': {
      width: '100% !important',
      '& .MuiCard-root': {
        marginTop: '1.5rem',
        '&:first-child': {
          marginTop: '0',
        },
        '& .MuiCardHeader-root': {
          padding: '1.5rem 1.25rem .75rem',
          '& .MuiCardHeader-avatar': {
            marginRight: '.75rem !important',
          },
          '& .MuiAvatar-root': {
            width: '2rem !important',
            height: '2rem !important',
          },
          '& .MuiTypography-root': {
            fontSize: '1.25rem !important',
          },
        },
        '& .MuiCardContent-root': {
          padding: '.75rem 1.25rem 1.5rem',
        },
        '& > .MuiDivider-root': {
          margin: '0 0 .75rem',
        },
        '&.no-header': {
          '& .MuiCardContent-root': {
            padding: '1.5rem 1.25rem',
          },
        },
      },
    },
  },
  jobDetailsStatus: {
    display: 'inline-flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: '1.25rem !important',
      marginRight: '.5rem',
    },
    '&.is-open': {
      '& .MuiSvgIcon-root': {
        color: '#4ba4da !important',
      },
    },
    '&.in-progress': {
      '& .MuiSvgIcon-root': {
        color: '#78c493 !important',
      },
    },
    '&.is-closed': {
      '& .MuiSvgIcon-root': {
        color: '#de5e6a !important',
      },
    },
  },
  // Data Group
  dataGroup: {
    '& .MuiTypography-root': {
      fontSize: '1.0625rem !important',
      lineHeight: '1.375',
    },
  },
  dataGroupInline: {
    '& .MuiTypography-root': {
      fontSize: '1.25rem !important',
      marginRight: '.5rem',
      '&:first-child': {
        fontWeight: '500 !important',
      },
    },
  },
  // Tags Group
  tagsGroup: {
    marginBottom: '0 !important',
    '& .MuiTypography-root': {
      fontSize: '1rem !important',
      textTransform: 'uppercase',
      margin: '0 0 .25rem',
    },
    '& .MuiBox-root': {
      display: 'flex',
      flexWrap: 'wrap',
    },
    '& .MuiChip-root': {
      fontSize: '1rem !important',
      color: '#4a5162 !important',
      backgroundColor: '#f5f5f5 !important',
      border: '1px solid #d1dbe3 !important',
      borderRadius: '.25rem !important',
      margin: '.25rem .5rem .25rem 0',
    },
    '& .MuiLink-root': {
      display: 'inline-block',
      marginTop: '.5rem',
    },
  },
  // Apply Dialog
  applyDialog: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent !important',
    },
    '& .MuiDialog-container': {
      justifyContent: 'flex-end',
    },
    '& .MuiDialog-paper': {
      maxWidth: '100%',
      maxHeight: '100%',
      width: '37.5rem',
      height: '100%',
      margin: '0',
    },
    '& .MuiDialogTitle-root': {
      position: 'relative',
      padding: '48px 24px 16px 32px!important',
      '& .MuiIconButton-root': {
        position: 'absolute',
        top: '.75rem',
        right: '.75rem',
      },
    },
    '& .MuiDialogContent-root': {},
  },
  applyDialogHeader: {
    position: 'relative',
    height: '6.5rem',
    paddingLeft: '7rem !important',
  },
  applyDialogContent: {
    padding: '2rem 2rem !important',
  },
  applyDialogSignature: {
    position: 'relative',
    border: '1px solid #90959f',
    margin: '5rem 0 2rem',
    '& .MuiTypography-root': {
      position: 'absolute',
      fontWeight: 500,
      textAlign: 'center',
      color: '#90959f !important',
      width: '10rem',
      backgroundColor: '#fff',
      top: '-.75rem',
      left: '50%',
      marginLeft: '-5rem',
    },
  },
  applyDialogNote: {
    padding: '1.25rem',
    border: '5px solid #c2def5',
    borderRadius: '.5rem',
  },
  // Button Social
  buttonSocial: {
    position: 'relative',
    backgroundColor: '#f1f3f5 !important',
    boxShadow: 'none !important',
    minHeight: '3.5rem',
    '&:hover': {
      backgroundColor: '#d8dadc !important',
    },
    '& img': {
      position: 'absolute',
      width: '2rem',
      height: '2rem',
      left: '2rem',
      top: '50%',
      marginTop: '-1rem',
    },
  },
  // Button Add
  buttonAdd: {
    // display: 'inline-flex',
    // alignItems: 'center',
    backgroundColor: '#fff !important',
    border: '1px solid #d1dbe3 !important',
    minWidth: '5rem !important',
    fontSize: '1rem !important',
  },
  // Info
  formInfoButton: {
    color: '#70c484 !important',
    paddingLeft: '0 !important',
  },
  // Form Proposal
  formProposal: {
    width: '100%',
  },
  formProposalLinkChoose: {
    fontSize: '1.0625rem !important',
    '& .MuiLink-root': {
      textTransform: 'uppercase',
      fontWeight: '500 !important',
      '&:first-child': {
        marginRight: '.375rem !important',
      },
      '&:last-child': {
        marginLeft: '.375rem !important',
      },
    },
  },
  formProposalOption: {
    marginTop: '.25rem',
  },
  // Card User Info
  cardUserInfo: {
    backgroundColor: '#f1f3f5 !important',
    border: '1px solid #d1dbe3',
    boxShadow: 'none !important',
    '& .MuiCardContent-root': {
      padding: '1.5rem !important',
    },
  },
  // List Item
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #d1dbe3',
    padding: '0.75rem 0',
    '& .MuiTypography-root': {
      fontSize: '1.0625rem',
      fontWeight: '500 !important',
      '& span': {
        fontWeight: '400 !important',
        color: '#90959f !important',
        marginLeft: '.375rem',
      },
    },
    '& .MuiLink-root': {
      fontWeight: '500 !important',
    },
    '&.is-large': {
      '& .MuiTypography-root': {
        fontSize: '1.375rem',
      },
    },
  },
  // Application Success
  applicationSuccessImage: {
    display: 'flex',
    justifyContent: 'center',
  },
  applicationSuccessText: {
    fontSize: '1.5rem !important',
    fontWeight: '500 !important',
    '@media (max-width: 768px)': {
      fontSize: '1.25rem !important',
    },
  },
  // Join Experfy Community Card
  joinExperfyCommunityCard: {
    border: '5px solid #c2def5 !important',
    '& .MuiCardContent-root': {
      padding: '1.5rem',
      '& > .MuiTypography-root': {
        fontSize: '1.25rem !important',
        lineHeight: '1.25 !important',
        margin: '1rem 0',
      },
    },
    '@media (max-width: 768px)': {
      '& .MuiCardContent-root': {
        padding: '1.25rem',
        '& > .MuiTypography-root': {
          fontSize: '1.125rem !important',
        },
      },
    },
  },
  // Forms
  formTextInput: {
    '& p': {
      display: 'inline-block',
      fontSize: '1.0625rem',
      fontWeight: 500,
      padding: '0 0 .125rem',
      color: '#4a5162',
    },
  },
  formAlert: {
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #e3795b',
    borderRadius: '0.25rem',
    padding: '0 0 0 5rem',
  },
  formAlertIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '5rem',
    borderRadius: '0.25rem 0 0 0.25rem',
    backgroundColor: '#e3795b',
    backgroundImage: `url(${FormAlertBackgroundIcon})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
  formAlertContent: {
    padding: '1.25rem',
    fontSize: '1.0625rem',
    color: '#e3795b',
    '& p': {
      lineHeight: '1.375',
      color: '#4a5162',
    },
    '& ul': {
      margin: '1rem 0 0',
      padding: '0 0 0 1.25rem',
    },
  },
});
