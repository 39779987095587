import { Box, Chip, Grid, SelectChangeEvent, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from '../../../styles/styles';
import FormSwitch from '../FormSwitch';
import { useEffect, useState } from 'react';
import { BasicFilters } from './BasicFilters';
import { AdvanceFilters } from './AdvanceFilters';
import { countries } from '../../../constants/FiltersConstants';
import { IFilter } from '../../../interfaces/IFIlter';

export const Filters = (props: { setReloadParams: any }) => {
  const { setReloadParams } = props;
  const classes = useStyles();
  const storedParams = window.localStorage.getItem('params') ? JSON.parse(window.localStorage.getItem('params') || '') : ({} as IFilter);
  const [searchFilters, setSearchFilters] = useState<{ [key: string]: any }>({});
  const [filterTags, setFilterTags] = useState<{ key: string; value: string }[]>([]);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [country, setCountry] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [selectedRadius, setSelectedRadius] = useState('');
  const [brandSwitch, setBrandSwitch] = useState(false);
  const [selectedPracticeArea, setSelectedPracticeArea] = useState('');
  const [selectedWorkType, setSelectedWorkType] = useState('');
  const [selectedPostDate, setSelectedPostDate] = useState('any-date');
  const [selectedRemuneration, setSelectedRemuneration] = useState('');
  const [selectedRateRange, setSelectedRateRange] = useState('');
  const [count, setCount] = useState(0);

  // Code to restrict
  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Filteres from store
  useEffect(() => {
    if (storedParams?.keywords?.length > 0) {
      setKeywords(storedParams.keywords);
    }

    if (storedParams?.filters && storedParams.filters !== '') {
      let newSearchFilters = searchFilters;
      const filtersArray = storedParams.filters.split('&');

      filtersArray.map((filter: string) => {
        const filterKey = filter.split('=')[0];
        const filterValue = filter.split('=')[1];

        if (filterKey.includes('preferred_country')) {
          const countryObj = Object.keys(countries).find((key) => countries[key] === filterValue);
          setCountry(`${countryObj}`);
          newSearchFilters = {
            ...newSearchFilters,
            preferred_country: filterValue,
          };
        }

        if (filterKey.includes('ideal_candidate_location')) {
          setLocation(filterValue);
          newSearchFilters = {
            ...newSearchFilters,
            ideal_candidate_location: filterValue,
          };
        }

        if (filterKey.includes('location_coordinates')) {
          const locationCoordinatesObj = JSON.parse(filterValue);
          setSelectedRadius(locationCoordinatesObj.distance);
          newSearchFilters = {
            ...newSearchFilters,
            location_coordinates: filterValue,
          };
        }

        if (filterKey.includes('open_to_remote')) {
          setBrandSwitch(true);
          newSearchFilters = { ...newSearchFilters, open_to_remote: true };
          addFilterTags('open_to_remote', 'Remote');
        }

        if (filterKey.includes('practice_areas')) {
          setSelectedPracticeArea(filterValue);
          newSearchFilters = {
            ...newSearchFilters,
            practice_areas: filterValue,
          };
        }

        if (filterKey.includes('work_type')) {
          setSelectedWorkType(filterValue);
          newSearchFilters = {
            ...newSearchFilters,
            work_type: filterValue,
          };
        }

        if (filterKey.includes('created_at')) {
          setSelectedPostDate(filterValue);
          newSearchFilters = {
            ...newSearchFilters,
            created_at: filterValue,
          };
        }

        if (filterKey.includes('compensation_type')) {
          setSelectedRemuneration(filterValue);
          newSearchFilters = {
            ...newSearchFilters,
            compensation_type: filterValue,
          };
        }

        if (filterKey.includes('min_rate')) {
          newSearchFilters = {
            ...newSearchFilters,
            min_rate: filterValue,
          };
        }

        if (filterKey.includes('max_rate')) {
          newSearchFilters = {
            ...newSearchFilters,
            min_rate: filterValue,
          };
        }
        return true;
      });

      setSearchFilters(newSearchFilters);
    }

    if (filterTags.length > 0) {
      window.localStorage.removeItem('params');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedParams]);

  //  Other filters
  const handleSwitchChange = (e: SelectChangeEvent) => {
    setBrandSwitch((prev) => !prev);
    let newSearchFilters = searchFilters;
    if (!brandSwitch) {
      newSearchFilters = { ...newSearchFilters, open_to_remote: !brandSwitch };
      addFilterTags('open_to_remote', 'Remote');
    } else {
      delete newSearchFilters['open_to_remote'];
      newSearchFilters = { ...newSearchFilters };
      removeFilterTags('open_to_remote');
    }
    setSearchFilters(newSearchFilters);
  };

  const addFilterTags = (tagKey: string, tagValue: string) => {
    let updatedTags = [...filterTags];

    if (updatedTags.find((tag) => tag.key === tagKey)) {
      updatedTags = updatedTags.map((tag) => {
        return tag.key === tagKey ? { ...tag, value: tagValue } : tag;
      });
    } else {
      updatedTags = [...updatedTags, { key: tagKey, value: tagValue }];
    }

    setFilterTags(updatedTags);
  };

  const removeFilterTags = (tagKey: string) => {
    let updatedTags = [...filterTags];
    const tag = updatedTags.find((tag) => tag.key === tagKey);

    if (tag) {
      const index = updatedTags.indexOf(tag);
      if (index !== -1) updatedTags.splice(index, 1);
    }

    if (tagKey === 'preferred_country' || tagKey === 'ideal_candidate_location') {
      const rTag = updatedTags.find((tag) => tag.key === 'location_coordinates');

      if (rTag) {
        const index = updatedTags.indexOf(rTag);
        if (index !== -1) updatedTags.splice(index, 1);
      }

      if (tagKey === 'preferred_country') {
        const rTag = updatedTags.find((tag) => tag.key === 'ideal_candidate_location');

        if (rTag) {
          const index = updatedTags.indexOf(rTag);
          if (index !== -1) updatedTags.splice(index, 1);
        }
      }
    }
    setFilterTags(updatedTags);
  };

  const handleDeleteFilterTag = (tagKey: string) => {
    removeFilterTags(tagKey);
    let newSearchFilters = searchFilters;
    if (tagKey === 'range') {
      delete newSearchFilters['max_rate'];
      delete newSearchFilters['min_rate'];
    } else if (tagKey === 'compensation_type') {
      delete newSearchFilters['compensation_type'];
      delete newSearchFilters['max_rate'];
      delete newSearchFilters['min_rate'];
    } else {
      delete newSearchFilters[`${tagKey}`];
    }

    newSearchFilters = { ...newSearchFilters };
    setSearchFilters(newSearchFilters);

    switch (tagKey) {
      case 'keyword':
        setKeywords([]);
        return;
      case 'preferred_country':
        setCountry('');
        return;
      case 'ideal_candidate_location':
        setLocation('');
        return;
      case 'location_coordinates':
        setSelectedRadius('');
        return;
      case 'open_to_remote':
        setBrandSwitch((prev) => !prev);
        return;
      case 'practice_areas':
        setSelectedPracticeArea('');
        return;
      case 'work_type':
        setSelectedWorkType('');
        return;
      case 'created_at':
        setSelectedPostDate('any-date');
        return;
      case 'compensation_type':
        setSelectedRemuneration('');
        return;
      case 'range':
        setSelectedRateRange('');
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    let newFilters = '';

    Object.keys(searchFilters).map((key: string) => {
      if (searchFilters[key]) {
        const otherFilter = typeof searchFilters[key] === 'object' ? JSON.stringify(searchFilters[key]) : searchFilters[key];
        newFilters = newFilters.length ? `${newFilters}&filter[${key}]=${otherFilter}` : `filter[${key}]=${otherFilter}`;
      }
      return newFilters;
    });

    if (count > 0 && !storedParams.filters) setReloadParams({ filters: newFilters, keywords: keywords, page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords, searchFilters, setReloadParams, count]);

  return (
    <>
      <Box className={classes.mainFilters}>
        <Box className={classes.contentContainer}>
          <Box className={classes.mainFiltersInner}>
            <>
              <Grid container spacing={2}>
                <BasicFilters
                  searchFilters={searchFilters}
                  setSearchFilters={setSearchFilters}
                  country={country}
                  setCountry={setCountry}
                  location={location}
                  setLocation={setLocation}
                  keywords={keywords}
                  setKeywords={setKeywords}
                  selectedRadius={selectedRadius}
                  setSelectedRadius={setSelectedRadius}
                  addFilterTags={addFilterTags}
                  removeFilterTags={removeFilterTags}
                />
                <Grid item sm={3} xs={12}>
                  <Box className={classes.mainFiltersSwitch}>
                    <FormSwitch label='Search only open to remote' brandSwitch={brandSwitch} handleSwitchChange={handleSwitchChange} />
                  </Box>
                </Grid>
              </Grid>
              <AdvanceFilters
                searchFilters={searchFilters}
                setSearchFilters={setSearchFilters}
                selectedPracticeArea={selectedPracticeArea}
                setSelectedPracticeArea={setSelectedPracticeArea}
                selectedWorkType={selectedWorkType}
                setSelectedWorkType={setSelectedWorkType}
                selectedPostDate={selectedPostDate}
                setSelectedPostDate={setSelectedPostDate}
                selectedRemuneration={selectedRemuneration}
                setSelectedRemuneration={setSelectedRemuneration}
                selectedRateRange={selectedRateRange}
                setSelectedRateRange={setSelectedRateRange}
                addFilterTags={addFilterTags}
                removeFilterTags={removeFilterTags}
              />
              {filterTags.length > 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={12} mt={1}>
                    <Stack className={classes.tagsGroup} direction='row' spacing={1}>
                      {filterTags.map((tag: any, index: number) => (
                        <Chip key={index} label={tag.value} deleteIcon={<CloseIcon />} onDelete={() => handleDeleteFilterTag(tag.key)} />
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </>
          </Box>
        </Box>
      </Box>
    </>
  );
};
