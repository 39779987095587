import { AnyAction } from 'redux';
import * as actionTypes from '../actions/UsersActions';
import { IUser } from '../interfaces/IUser';

export type State = {
  readonly user: IUser;
  usersData: IUser[];
};

const initialState: State = {
  user: {} as IUser,
  usersData: [],
};

export const UsersReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.SET_INDIVIDUAL_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case actionTypes.SET_USERS_DATA: {
      return {
        ...state,
        usersData: action.payload,
      };
    }
    default:
      return state;
  }
};
