import { Box, Typography } from '@mui/material';
import { useStyles } from '../../styles/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { UsePagination } from '../common/Pagination';
// import { PromoCard } from '../common/PromoCard';
import { Filters } from '../common/Filters/Filters';
import Navbar from '../common/Navbar';
import Record from './Record';
import { getPublicPagesData } from '../../services/PublicPagesApi';
import { IPublicPage } from '../../interfaces/IPublicPage';
import { IFilter } from '../../interfaces/IFIlter';
import Loader from '../common/Loader';
import SEO from '../common/SEO';
import { getUsers } from '../../services/UserApi';

export const ManagePublicPages = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.loader.loading);
  const publicPagesMetaData = useSelector((state: RootState) => state.publicPages.publicPagesMetaData);
  const storedParams = window.localStorage.getItem('params') ? JSON.parse(window.localStorage.getItem('params') || '') : ({} as IFilter);
  const [reloadParams, setReloadParams] = useState<IFilter>({ filters: storedParams.filters, keywords: storedParams.keywords, page: 1 });

  useEffect(() => {
    if (reloadParams.filters !== undefined) dispatch(getPublicPagesData(reloadParams));
  }, [dispatch, reloadParams]);

  useEffect(() => {
    if (publicPagesMetaData.records?.length > 0) {
      const ownerIds = publicPagesMetaData.records.filter((record: IPublicPage) => record.owner_id).map((record: IPublicPage) => record.owner_id);
      if (ownerIds && ownerIds.length > 0) {
        const uniqOwnerIds = ownerIds.filter((item, index) => ownerIds.indexOf(item) === index);
        dispatch(getUsers(uniqOwnerIds));
      }
    }
  }, [publicPagesMetaData, dispatch]);

  const handlePageChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: number) => {
    window.scrollTo(0, 450);
    setReloadParams({ ...reloadParams, page: value });
  };

  return (
    <>
      <SEO title='Browse Job Opportunities | Projects' />
      <Navbar />
      {loading && <Loader />}
      <Box className={classes.jobsBanner}>
        <Box className={classes.jobsBannerInner}>
          <Typography className={classes.jobsBannerTitle} variant='h1'>
            Exclusive jobs, contracts and projects rightly matched and delivered to you.
          </Typography>
          <Typography className={classes.jobsBannerText}>
            Experfy is building an innovative Future of work model that will bring exclusive jobs, contracts and projects to you in a hassle free way. Once you become our Talent Community member,
            instead of you applying to jobs, hiring managers seek you out, giving you the attention you deserve. Create a profile today!
          </Typography>
        </Box>
      </Box>
      <Box className={classes.mainHeader}>
        <Box className={classes.contentContainer}>
          <Box className={classes.mainHeaderInner}>
            <Typography variant='h3'>Browse Job Opportunities and Projects</Typography>
          </Box>
        </Box>
      </Box>
      <Filters setReloadParams={setReloadParams} />

      <Box className={classes.mainContent}>
        <Box className={classes.contentContainer}>
          {(reloadParams.filters !== '' || reloadParams.keywords?.length > 0) && !loading && (
            <Typography variant='h4'>{`${publicPagesMetaData.meta.page.total} ${publicPagesMetaData.meta.page.total > 1 ? 'records' : 'record'}  matching your criteria`}</Typography>
          )}

          {publicPagesMetaData.records &&
            publicPagesMetaData.records.map((row: IPublicPage, index: number) => (
              <div key={index}>
                <Record record={row} reloadParams={reloadParams} />
              </div>
            ))}
          <UsePagination
            count={publicPagesMetaData.meta.page.total ? Math.ceil(publicPagesMetaData.meta.page.total / publicPagesMetaData.meta.page.per_page) : 0}
            page={publicPagesMetaData.meta.page.page}
            handlePageChange={handlePageChange}
          ></UsePagination>
          {/* <PromoCard></PromoCard> */}
        </Box>
      </Box>
    </>
  );
};
