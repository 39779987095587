import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import './index.css';
import { Box, CssBaseline } from '@mui/material';
import { ManagePublicPages } from './components/publicPages/ManagePublicPages';
import { Overview } from './components/publicPages/Overview';

function App() {
  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            height: '100%',
          }}
        >
          <Routes>
            <Route path={'/'} element={<ManagePublicPages />} />
            <Route path={':identifier/:id/overview'} element={<Overview />} />
          </Routes>
        </Box>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
