import { IPublicPageMeta } from './../interfaces/IPublicPageMeta';
import { IPublicPage } from '../interfaces/IPublicPage';

export const GET_PUBLIC_PAGE_DATA = 'GET_PUBLIC_PAGE_DATA';
export const GET_DATA_BY_ID = 'GET_DATA_BY_ID';
export const GET_OWNER_BY_ID = 'GET_OWNER_BY_ID';
export const API_ERRORS = 'API_ERRORS';

export const getPublicPagesAction = (payload: IPublicPageMeta) => {
  return { type: GET_PUBLIC_PAGE_DATA, payload };
};

export const getDataByIdAction = (payload: IPublicPage) => {
  return { type: GET_DATA_BY_ID, payload };
};

export const apiErrorAction = (errors: any) => {
  return { type: API_ERRORS, errors };
};
