import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  loadingContainer: {
    zIndex: '99999',
    position: 'fixed',
    height: "100%",
    top: '0rem',
    left: '0rem',
  },
  loadingAnimation: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    zoom: "1",
  },
  loadingAnimationTitle: {
    fontSize: "1.125rem !important",
    fontWeight: "600 !important",
    textAlign: "center",
    padding: "0 0 .5rem",
  },
  loadingAnimationSvg: {
    zoom: ".5",
  },
});

function Loader() {
  const classes = useStyles();

  return (
    <Grid container justifyContent={"center"} pt={2} pb={2} className={classes.loadingContainer}>
      <Box className={classes.loadingAnimation}>
        <Typography className={classes.loadingAnimationTitle}>
          Loading...
        </Typography>
        <Box className={classes.loadingAnimationSvg}>
          <svg
            className="whole"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="loader">
              <animateTransform
                xlinkHref="#loader"
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                dur="1s"
                begin="0s"
                repeatCount="indefinite"
                restart="always"
              />
              <path
                className="a"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM50 90C72.0914 90 90 72.0914 90 50C90 27.9086 72.0914 10 50 10C27.9086 10 10 27.9086 10 50C10 72.0914 27.9086 90 50 90Z"
                fill="#d1dbe3"
              />
              <path
                className="b"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M100 50C100 22.3858 77.6142 0 50 0V10C72.0914 10 90 27.9086 90 50H100Z"
                fill="#4ba4da"
              />
            </g>
          </svg>
        </Box>
      </Box>
    </Grid>

  );
}

export default Loader;
